<template>
  <div>
    <div v-if="showAdminTable" class="room-list">
      <AdminTable :title="building && building.buildingName ? `${building.buildingName} - Rooms` : 'All Rooms'"
                  :list="roomList" :fields="fields" :loading="loading" :availabilityButton="true"
                  @update-list="update" @edit="editRoom" @delete="deleteRoom" @restore="restoreRoom"
                  @show-availability="showAdminTable = false"/>
      <CalendarModal :periods="periodsSelected"/>
    </div>
    <div v-else>
      <AdminAvailabilityTable :title="building && building.buildingName ? `${building.buildingName} - Rooms Availability` : 'All Rooms Availability'"
                              :list="roomList" :loading="loading" :unitsButton="true" :manageAvailability="false"
                              @update-list="update" @show-units="showAdminTable=true"/>
    </div>
  </div>
</template>

<script>
import AdminTable from '@/components/admin/AdminTable'
import AdminAvailabilityTable from '@/components/admin/AdminAvailabilityTable'
import CalendarModal from '@/components/admin/calendar/CalendarModal'
import {buildingService, roomService} from '@/services/admin'
import {mapState} from 'vuex'

export default {
  name: 'AdminAllRooms',
  components: {
    AdminTable,
    AdminAvailabilityTable,
    CalendarModal,
  },
  data() {
    return {
      roomList: [],
      loading: false,
      periodsSelected: [],
      showAdminTable: true
    }
  },
  computed: {
    ...mapState({
      building: state => state.adminBuildingStore.building,
    }),
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name' },
        { key: 'buildingName', label: 'Building' },
        { key: 'type.name', label: 'Type' },
        { key: 'sqMeters', label: 'Square meters' },
        { key: 'description', label: 'Description' },
        { key: 'adminComment', label: 'Admin Comment' },
        { key: 'floor', label: 'Floor' },
        { key: 'numOfPerson', label: 'Num Of Person' },
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['edit', 'calendar', 'delete'] },
      ]
    },
  },
  async beforeMount() {
    await this.update()
  },
  destroyed() {
    this.$store.commit('adminBuildingStore/setBuilding', null)
  },
  methods: {
    // openCalendar(room) {
    //   this.$store.commit('adminRoomStore/setRoom', room)
    //   this.periodsSelected = room.periods;
    //   this.$bvModal.show('calendar-modal')
    // },
    async update() {
      this.loading = true
      this.roomList = this.building ? this.building.rooms : await roomService.getRooms()
      this.loading = false
    },
    async updateAfterDeleteOrRestore(buildingId) {
     // this.$store.commit('adminBuildingStore/setBuilding', buildingService.getBuildings())
      this.loading = true
      this.roomList = this.building ? await buildingService.getAllRoomsOfBuilding(buildingId) : await roomService.getRooms()
      this.loading = false
    },
    editRoom(room) {
      this.$store.commit('adminRoomStore/setRoom', room)
      this.$router.push({ name: 'room-edit', query: { id: room.id }})
    },
    async deleteRoom(room) {
      this.$store.commit('adminRoomStore/setRoom', null)
      const response = await roomService.deleteRoom(room.id)
      await this.updateAfterDeleteOrRestore(room.buildingId)
    },
    async restoreRoom(room) {
      await roomService.restoreRoom(room.id)
      await this.updateAfterDeleteOrRestore(room.buildingId)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
